import dayjs from "dayjs";
import { useState, useEffect } from "react";

const useGMTTime = (cityInfo) => {
  const [offset, setOffset] = useState(0);
  const [formattedTime, setFormattedTime] = useState("");

  
  useEffect(() => {
    if (cityInfo) {
      setOffset(cityInfo.tz_offset);
}
  },[cityInfo])
    // Step 2: Parse the JSON data

  
 
  useEffect(() => {
    const updateFormattedTime = () => {
      let date = new Date();
      let utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
      let localTime = new Date(utcTime + offset * 3600000);

      let formattedTime =
        localTime.getFullYear() +
        "-" +
        String(localTime.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(localTime.getDate()).padStart(2, "0") +
        " " +
        String(localTime.getHours()).padStart(2, "0") +
        ":" +
        String(localTime.getMinutes()).padStart(2, "0") +
        ":" +
        String(localTime.getSeconds()).padStart(2, "0");

      setFormattedTime(formattedTime);
    };

    updateFormattedTime();
    const interval = setInterval(updateFormattedTime, 1000);

    return () => clearInterval(interval);
  }, [offset]);

  return { formattedTime };
};

export default useGMTTime;


// try {
//   let data = {
//     amount: tripaccept.trip_pay_amount,
//     trip_id:tripid,
//     driver_id:driver_Id,
//     pay_mode:"Card",
//     rider_amt:tripaccept.trip_pay_amount,
//     pay_amount:tripaccept.trip_pay_amount,
//     promo_amt:tripaccept.trip_promo_amt,
//     commission_amt:tripaccept.trip_comp_commision,
//     trip_driver_commision:tripaccept.trip_driver_commision,
//     trans_description:"Trip Payment"
//   };

//   const result = await ApiService.post(
//     `/transactionapi/addtriptrans?api_key=${apikey}&&user_id=${user_id}`,
//     data
//   );
//   if (result?.status === 200) {

//     const data = {
//       trip_id: tripid,
//       trip_status: "completed",
//       trip_pay_status: "Paid",
//       trip_pay_date: date,
//       trip_pay_mode: "Card",
//     };

//     try {
//       const response = await ApiService.post(
//         `/tripapi/updatetrip?api_key=${apikey}`,
//         data
//       );
//       if (response.status === 200) {
//         setride(false);

//         setThank(true);
//         // console.log(response.data.response)
//       }
//     } catch (error) {
//       console.log("Error::", error);
//     }

//   }
// } catch (error) {
//   console.log("Error::", error);
// }
