// api.js
import axios from "axios";

export const fetchHandler = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        return response.data.resDecrypt;
    } catch (error) {
        console.error("Error in API request:", error.message);
        throw error;
    }
};




const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 25000,
  headers: {
    "Content-Type": "application/json",
  },
};

// axios instance creation
export const ApiServiceLoaderless = axios.create(config);

const ApiService = axios.create(config);

// request interceptor
ApiService.interceptors.request.use(
  async (config) => {
    // do something before request is sent
    return config;
  },
  (error) => {
    //  do something if error
    return Promise.reject(error);
  }
);

// response interceptor
ApiService.interceptors.response.use(
  async (response) => {
    // do something post getting response
    return response;
  },

  async (error) => {
    // do something if some error
    return Promise.reject(error);
  }
);

export default ApiService;





// import axios from "axios";

// const config = {
//     baseURL: process.env.REACT_APP_URL,
//     timeout: 25000,
//     headers: {
//         "Content-Type": "application/json",
//     },
// };

// // axios instance creation
// export const KonfehtiServiceLoaderless = axios.create(config);
// const KonfehtiService = axios.create(config);

// // request interceptor
// // KonfehtiService.interceptors.request.use(
// //     async (config) => {
// //         // spinning start to show
// //         document.body.classList.add("loading-indicator");
// //         return config;
// //     },
// //     (error) => {
// //         document.body.classList.remove("loading-indicator");
// //         return Promise.reject(error);
// //     }
// // );

// // response interceptor
// // KonfehtiService.interceptors.response.use(
// //     async (response) => {
// //         // spinning hide
// //         document.body.classList.remove("loading-indicator");
// //         return response;
// //     },

// //     async (error) => {
// //         document.body.classList.remove("loading-indicator");
// //         return Promise.reject(error);
// //     }
// // );

// export default KonfehtiService;