import {
    AddressElement,
    LinkAuthenticationElement,
    PaymentElement,
  } from "@stripe/react-stripe-js";
  import { useEffect, useState } from "react";
  import { useStripe, useElements } from "@stripe/react-stripe-js";
import ApiService from "../../utils/Api";
import useGMTTime from "../Time/time";
import moment from "moment";
  
  export default function CheckoutForm(props) {
    const {
        setThank,
        setride,
        date,
        payAmount,
        setDriverCancel,
        closePayHandle,
        offers,
        clientSecret,
        setCancelStatus,
        declineNotificationApi,
        cancelStatus,
        trip_Id
      } = props;
  
    const wamt = sessionStorage.getItem("walletRechargeAmount");
    const city_cur = sessionStorage.getItem("city_cur");
  
    const stripe = useStripe();
    const elements = useElements();
  
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const apikey = sessionStorage.getItem("key");
      const userid = sessionStorage.getItem("userid");
      const tripaccept = sessionStorage.getItem("tripaccept");
      const driver_Id = sessionStorage.getItem("driver_Id");
      const tripid = localStorage.getItem("tripid");
      const cityd = localStorage.getItem("cityInfo");
      let cityInfo;
    
      if (cityd) {
        try {
          cityInfo = JSON.parse(cityd);
        } catch (error) {
          console.error("Invalid JSON:", error);
        }
      } else {
        console.error("No city data found");
      }
    
      // console.log(cancelStatus)
     
    
      const trip_pay_mode = sessionStorage.getItem("trip_pay_mode");

      var d = new Date();
  var formattedTime = moment.utc(d).format("YYYY-MM-DD HH:mm:ss");
 
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsProcessing(true);
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`,
        },
        redirect: "if_required",
      });
      if (!error) {

        if (cancelStatus) {
          declineNotificationApi()
      
        } else {
          try {


            let data = {
              total_amt: JSON.parse(tripaccept).trip_pay_amount,
              trip_id:tripid?tripid:JSON.parse(tripaccept).trip_id,
              driver_id:driver_Id != undefined ?driver_Id:JSON.parse(tripaccept).driver_id,
              pay_mode:"Card",
              rider_amt:JSON.parse(tripaccept).trip_pay_amount,
              pay_amount:JSON.parse(tripaccept).trip_pay_amount,
              promo_amt:JSON.parse(tripaccept).trip_promo_amt,
              commission_amt:JSON.parse(tripaccept).trip_comp_commision,
              trip_driver_commision:JSON.parse(tripaccept).trip_driver_commision,
              trans_description:"Trip Payment"
            };
          
            const result = await ApiService.post(
              `/transactionapi/addtriptrans?api_key=${apikey}&&user_id=${userid}`,
              data
            );
            if (result?.status === 200) {
          
                // const status = cancelStatus ? "cancel" : "completed" 
                // if (cancelStatus) {
                //   declineNotificationApi();
                //     setCancelStatus(true);
                // }
                
              const data = {
                trip_id: tripid?tripid:JSON.parse(tripaccept).trip_id,
                trip_status: "completed",
                trip_pay_status: "Paid",
                trip_pay_date: formattedTime,
                trip_pay_mode: "Card",
              };
          
              // const params = {
              //   trip_id: tripid,
              //   trip_status: "cancel",
              //   trip_pay_status: "Paid",
              //   trip_pay_date: formattedTime,
              //   trip_pay_mode: "Card",
              //   is_return_details: 1,
              //   can_fee_by: "r",
              //   is_cancelled: 1
                
              // };
              
          
              try {
                const response = await ApiService.post(
                  `/tripapi/updatetrip?api_key=${apikey}`,
                   data
                );
                if (response.status === 200) {
                   setride(false);
          
                  setThank(true);
                  
                    sessionStorage.removeItem("trip_id")
                    setDriverCancel(true);
                    closePayHandle(false)
                    setride(true);
                }
              } catch (error) {
                console.log("Error::", error);
              }
          
            }
          } catch (error) {
            console.log("Error::", error);
          }
        }


      } else if (
        error.type === "card_error" ||
        error.type === "validation_error"
      ) {
        setMessage(error.message);
      } else {
        console.log(error);
        setMessage(error);
      }
  
      setIsProcessing(false);
    };
  
    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        {/*       <AddressElement id="address-element" options={{ mode: "billing" }} />
        <LinkAuthenticationElement
          id="link-authentication-element"
        /> */}
        <PaymentElement id="payment-element" />
        <button disabled={isProcessing || !stripe || !elements} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    );
  }
  