import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

const Header = ({ isAuthenticated }) => {
  const [print, setprint] = useState(false);
  const [addrressInfo, setAddrressInfo] = useState("");
  const [emailInfo, setEmailInfo] = useState("");
  const [phoneInfo, setPhoneInfo] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const closeNavbar = () => {
    setIsCollapsed(true);
  };
  // vs-body-visible
  // async function fetchData() {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_STRAPI_API_URL}/api/footer-navbars?populate=*`);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const data = await response.json();
  //     setAddrressInfo(data.data[0].attributes.ContactInfo[0].Value);
  //     setEmailInfo(data.data[0].attributes.ContactInfo[1].Value);
  //     setPhoneInfo(data.data[0].attributes.ContactInfo[2].Value);
  //     setLogoURL(data.data[0].attributes.Logo.data.attributes.url);
  //   } catch (error) {
  //     console.error('There was a problem fetching the data:', error);
  //   }
  // }

  // fetchData();
  const navigate = useNavigate();

  useEffect(() => {
    let username = sessionStorage.getItem("phone");
    if (username === "" || username === null) {
      setprint(true);
    }
  }, []);

  return (
    <>
      <header class="tj-header">
        {/* <!--Header Content Start--> */}
        <div class="container">
          <div class="row">
            {/* <!--Toprow Content Start--> */}
            <div class="col-md-7 col-sm-6 col-xs-12 main-logo">
              <div
                class="tj-logo"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <span>
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{
                      height: "160px",
                      margin: "auto",
                      marginTop: "20px",
                      display: "block",
                    }}
                    alt="logo"
                    src="images/logo.png"
                  />
                </span>
                <span class="logotext" style={{ fontSize: 16, fontWeight: 600, color: "#000" } }></span>
              </div>

              {/* <!--Logo End--> */}
            </div>
            <div style={{display:"block"}}>
            <span style={{position:"absolute", fontSize:18, fontWeight:600, padding:"24px 79px", color: "#000" }}>Connect with us</span>

            <div class="social-css" style={{display:"flex", justifyContent:"space-between", textAlign:"center", padding:"70px", marginTop:15}}>
             
                <span class="social-media">
                  <a
                    href="https://www.facebook.com/nearbyservices1"
                    target="_blank"
                    rel="noreferrer"
                  >
                  <SocialIcon url="https://www.facebook.com/nearbyservices1" />
                  </a>
                </span>
                <span class="social-media">
                  <a
                    href="https://www.tiktok.com/@getservice1"
                    target="_blank"
                    rel="noreferrer"
                  >
                  <SocialIcon url="https://www.tiktok.com/@getservice1" />
                  </a>
                </span>

           {/*      <span class="social-media"> 
                  <a
                    href={process.env.REACT_APP_LINKED_IN_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialIcon url="https://linkedin.com/in/couetilc" />
                  </a>
                </span> */}

                <span class="social-media">
                  <a
                    href="https://www.instagram.com/getservice1/"
                    target="_blank"
                    rel="noreferrer"
                  >
                  <SocialIcon url="https://www.instagram.com/getservice1/" />
                  </a>
                </span>
                <span class="social-media">
                  <a
                    href="https://x.com/GetServiceCa"
                    target="_blank"
                    rel="noreferrer"
                  >
                  <SocialIcon url="https://x.com/GetServiceCa" />
                  </a>
              </span>
              {/* <span class="social-media">
              <a
                href={process.env.REACT_APP_INSTRAGRAM_URL}
                target="_blank"
                rel="noreferrer"
              >
              <SocialIcon url="https://threads.net/in/couetilc" />
              </a>
              </span> */}
              <span class="social-media">
              <a
                href="https://www.youtube.com/@getservice1"
                target="_blank"
                rel="noreferrer"
              >
              <SocialIcon url="https://www.youtube.com/@getservice1" />
              </a>
                </span>
                </div>
             </div>
            {/* <div class="col-md-3 col-sm-4 col-xs-12">
              <div class="info_box">
                <i class="fa fa-home"></i>
                <div class="info_text add-format">
                  <span class="info_title ">Address</span>
                  <span>{process.env.REACT_APP_BUSINESS_ADDRESS}</span>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-12">
              <div class="info_box">
                <i class="fa fa-envelope"></i>
                <div class="info_text">
                  <span class="info_title">Email Us</span>

                  <span>
                    <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                      {process.env.REACT_APP_EMAIL}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-xs-12">
              <div class="phone_info">
                <div class="phone_icon">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <div class="phone_text">
                  <span>
                    <a
                      href={`tel:${process.env.REACT_APP_BUSINESS_PHONE_NUMBER.replace(
                        /\s/g,
                        ""
                      )}`}
                    >
                      {process.env.REACT_APP_BUSINESS_PHONE_NUMBER}
                    </a>
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div class="tj-nav-row">
          <div class="container">
            <div class="row">
              <div class="tj-nav-holder">
                <nav class="navbar navbar-default">
                  <div
                    class="navbar-header"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      class="logo-mobile"
                      style={{
                        width: "40%",
                        margin: "auto",
                        marginTop: 0,
                        marginBottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "18%",
                      }}
                    >
                      <img src="images/logo.png" height="60px" width="70px" />
                    </div>

                    <button
                    type="button"
                    className="navbar-toggle collapsed"
                    // data-toggle="collapse"
                    // data-target="#tj-navbar-collapse"
                    aria-expanded={!isCollapsed}
                    onClick={toggleNavbar}
                  >
                    <span className="sr-only">Menu</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  </div>

                  
                  {/* <!-- Navigation Content Start --> */}
             
        <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'in'}`} id="tj-navbar-collapse">
        <ul className="nav navbar-nav">
          <li className="dropdown" onClick={closeNavbar}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={closeNavbar}>
            <Link to="/about-us">About Us</Link>
          </li>
          <li onClick={closeNavbar}>
            <Link to="/services">Services</Link>
          </li>
          <li onClick={closeNavbar}>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li onClick={closeNavbar}>
            <Link to="/blog">Blog</Link>
          </li>
          <li onClick={closeNavbar}>
            <Link to="/article">Article</Link>
          </li>
          <li onClick={closeNavbar}>
            <Link to="/announcement">Announcement</Link>
          </li>
          {!isAuthenticated ? (
            <li onClick={closeNavbar}>
              <Link to="/login">Login</Link>
            </li>
          ) : (
            <li onClick={closeNavbar}>
              <Link to="/profile">My Account</Link>
            </li>
          )}
        </ul>
      </div>
                  {/* <!-- Navigation Content Start --> */}
                </nav>
                {/* <!--Menu Holder End--> */}
                <div class="book_btn">
                  <Link to="/booknow">
                    Book Now{" "}
                    <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
              {/*<!--Nav Holder End--> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
