import {
  PaymentElement,
  LinkAuthenticationElement,
  AddressElement,
  ExpressCheckoutElement,
} from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import ApiService from "../../utils/Api";
import useGMTTime from "../Time/time";

export default function CheckoutForm(props) {
  const {
    setThank,
    setride,
    date,
    payAmount,
    setDriverCancel,
    closePayHandle,
    offers,
    clientSecret,
    startTimer
  } = props;

  const cityInfo = sessionStorage.getItem("cityInfo");

  const { formattedTime } = useGMTTime(cityInfo);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  // const [clientSecret, setClientSecret] = useState("");

  const apikey = sessionStorage.getItem("key");
  const user_id = sessionStorage.getItem("userid");
  const driver_Id = sessionStorage.getItem("driver_Id");
  const tripid = localStorage.getItem("tripid");
  const tripaccept = sessionStorage.getItem("tripaccept");
  const [iosToken, setIosToken] = useState("");
  const [andrToken, setAndrToken] = useState("");
  const [paymentIntent, setPaymentIntent] = useState();
  const city_cur = sessionStorage.getItem("city_cur")

 
/*   useEffect(() => {
    // Create payment intent on component mount
    const createPaymentIntent = async () => {
      const response = await fetch("/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
    };

    createPaymentIntent();
  }, []);
   */

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    // Confirm the PaymentIntent
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });
    setPaymentIntent(paymentIntent)
    if (error) {
      setMessage(error.message);
      setIsProcessing(false);
      return;
    }
 
    // Check the PaymentIntent status and proceed accordingly
    if (paymentIntent.status === "requires_capture") {
      tripAcceptHandle()

       // PaymentIntent is confirmed, but requires capture
      setMessage("Payment is successfully processed upon trip completion, with the amount being automatically deducted.");
      // Optionally, notify the user that payment needs manual processing
    } else if (paymentIntent.status === "succeeded") {
      // PaymentIntent has already been captured successfully
      setMessage("Payment was successful!");
    } else {
      setMessage("Payment could not be completed.");
    }

    setIsProcessing(false);
  };
  const paymentElementOptions = {
    layout: "tabs",
  };

  const tripSend = async () => {
    
    const data = {
      trip_id: tripid
    }
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/constantapi/paymentsave`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  }
 
  const tripAcceptHandle = async () => {
    var range = "0123456789";
    var otpval = "";

    for (var i = 0; i < 4; i++) {
      otpval += range[Math.floor(Math.random() * 10)];
    }

    if (!otpval) return;

    offers?.forEach((data) => {
      if (data.driver_id === driver_Id) {
        sessionStorage.setItem("DriverName", data.Driver.d_name);
        sessionStorage.setItem("DriverContact", data.Driver.d_phone);
        sessionStorage.setItem("D_Device", data.Driver.d_device_type); // fixed casing
        sessionStorage.setItem("DriverToken", data.Driver.d_device_token);
      }
    });

    const dtype = sessionStorage.getItem("D_device");
    const dtoken = sessionStorage.getItem("DriverToken");

    if (dtype == "Android") {
      setAndrToken(dtoken);
    } else {
      setIosToken(dtoken);
    }

    if (!iosToken && !andrToken) {
      return;
    }

    const params2 = {
      trip_id: tripid,
      trip_status: "accept",
      driver_id: driver_Id,
      trip_pay_status:"Paid",
      trip_pay_amount: payAmount,
      tm_acc: formattedTime,
      otp: otpval,
      payment_intent_id: paymentIntent.id,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/tripapi/tripaccept?api_key=${apikey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params2),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      tripSend()
      startTimer()

      sessionStorage.setItem("veri-otp", otpval);
      sessionStorage.setItem("ride", false);
      sessionStorage.setItem("tripaccept", JSON.stringify(data.response));
      setDriverCancel(true);
      closePayHandle(false)
      setride(true);
      startTimer()
      const params = {
        trip_id: tripid,
        trip_status: "accept",
        android: andrToken,
        ios: iosToken,
        to: "driver",
        message: "Your offer has been accepted",
      };


      try {
        const response = await fetch(
          `https://apis.getservice.ca/backend/notification/send?api_key=${apikey}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();

        // console.log("Notification sent:", result);
      } catch (error) {
        console.error("Error sending notification:", error);
      }

   
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };


  useEffect(()=>{
    tripAcceptHandle()
  },[paymentIntent])

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
{/*       <ExpressCheckoutElement />
      <AddressElement id="address-element" />
      <LinkAuthenticationElement
        id="link-authentication-element"
     
      /> */}
      <PaymentElement
        id="payment-element"
       />
      <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
