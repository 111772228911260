import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [addrressInfo, setAddrressInfo] = useState("");
  const [emailInfo, setEmailInfo] = useState("");
  const [phoneInfo, setPhoneInfo] = useState("");
  // const [logoURL, setLogoURL] = useState("");
  const [footerTitle, setFooterTitle] = useState("");
  const [footerDes, setFooterDes] = useState("");
  const [copyright, setCopyright] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // Call the function to scroll to top when the component mounts

    // async function fetchData() {
    //   try {
    //     const response = await fetch(
    //       `${process.env.REACT_APP_STRAPI_API_URL}/api/footer-navbars?populate=*`
    //     );
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     const data = await response.json();
    //     setAddrressInfo(data.data[1].attributes.ContactInfo[0].Value);
    //     setEmailInfo(data.data[1].attributes.ContactInfo[1].Value);
    //     setPhoneInfo(data.data[1].attributes.ContactInfo[2].Value);
    //     // setLogoURL(data.data[1].attributes.Logo.data.attributes.url);
    //     setFooterTitle(data.data[1].attributes.ContactInfo[3].Name);
    //     setFooterDes(data.data[1].attributes.ContactInfo[3].Value);
    //     setCopyright(data.data[1].attributes.ContactInfo[4].Value);
    //   } catch (error) {
    //     console.error("There was a problem fetching the data:", error);
    //   }
    // }

    // fetchData();

    handleScrollToTop();
  }, []);
  return (
    <>
      <section class="tj-footer">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="about-widget widget">
                <h3>About GetRide</h3>
                <p>
                  GetRide is a global transportation app developed by
                  GetService, a leading Canadian software company. Designed to
                  offer a seamless and sophisticated travel experience, GetRide
                  connects users with reliable, convenient, and affordable rides
                  around the world.<br/><br/> Our app combines cutting-edge technology
                  with a commitment to excellence, ensuring every journey is
                  efficient, safe, and tailored to your needs. Whether
                  you&apos;re traveling for business, pleasure, or exploring new
                  destinations, GetRide provides a superior transportation
                  experience that meets your expectations.<br/><br/> Discover the ease and
                  elegance of travel with GetRide, powered by GetService.
                </p>
                <ul class="fsocial-links">
                  {process.env.REACT_APP_FACEBOOK_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                  )}
                  {process.env.REACT_APP_TWITTER_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_TWITTER_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                  )}

                  {process.env.REACT_APP_LINKED_IN_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_LINKED_IN_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  )}

                  {process.env.REACT_APP_PINTEREST_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_PINTEREST_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  )}
                  {process.env.REACT_APP_INSTRAGRAM_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_INSTRAGRAM_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div class="col-md-6">
              <div class="contact-info widget">
                <h3>Contact Info</h3>
                <ul class="contact-box">
                  {/* <li>
                    <i class="fas fa-home" aria-hidden="true"></i>{" "}
                    {process.env.REACT_APP_BUSINESS_ADDRESS}
                  </li> */}
                  <li>
                    <i class="fas fa-home" aria-hidden="true"></i> 714 York Street, London, Ontario, Canada. N5W 2S8{" "}
                  </li>
                  <li>
                    <i class="far fa-envelope-open"></i>
                    {/* <a href={`mailto:${process.env.REACT_APP_BUSINESS_EMAIL}`}>
                      {process.env.REACT_APP_BUSINESS_EMAIL}
                    </a> */}
                    <a href={`mailto:support@getservice.ca`}>
                      support@getservice.ca{" "}
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-phone-square"></i>
                    <a href={`tel:12265829299`}>+1 (226) 582-9299 </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Footer Content End--> */}
      {/* <!--Footer Copyright Start--> */}
      <section class="tj-copyright">
        <div class="container">
          <div class="">
            <div className="">
              <div>
                {/*     <p>
               {copyright}
              </p> */}
                <p>&copy; Copyrights 2024 GetRide. All Rights Reserved.</p>
              </div>

              <div
                className="priv-term"
                style={{
                  cursor: "pointer",
                }}
              >
                <p
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    navigate("/term-condition");
                  }}
                >
                  Terms & conditions
                </p>
                <p
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                >
                  Privacy policy
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Footer;
