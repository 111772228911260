import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout";
import { loadStripe } from "@stripe/stripe-js";

function PaymentComponent(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/constantapi/getstripekey`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(async (r) => {
      const response = await r.json();
      // console.log( response.response.stripe_p_dev_key )
      setStripePromise(loadStripe(response.response.stripe_p_key));
    });
  }, []);

  // useEffect(() => {
  //   fetch("https://apps.getservice.ca/webservices/1.1.0/index.php/constantapi/createpaymentintent", {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ amount: Math.round(props.payAmount * 100) }),
  //   }).then(async (result) => {
  //     var { clientSecret } = await result.json();
  //     setClientSecret(clientSecret);
  //   });
  // }, []);

  // console.log("am", props.payAmount);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/constantapi/createpaymentintent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: props.payAmount,
          currency: props.city_cur,
          description: "getride booking website",
        }),
      }
    ).then(async (result) => {
      var response = await result.json();

      // console.log(response);
      setClientSecret(response.response);
    });
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        {/*         <img src="images/logo.png" height={"100px"} />
         */}{" "}
        <h1 style={{ color: "blueviolet", fontWeight: "700" }}>Stripe</h1>
      </div>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            setride={props.setride}
            clientSecret={clientSecret}
            setThank={props.setThank}
            date={props.date}
            offers={props.offers}
            payAmount={props.payAmount}
            setDriverCancel={props.setDriverCancel}
            closePayHandle={props.closePayHandle}
            startTimer={props.startTimer}
          />
        </Elements>
      )}
    </div>
  );
}

export default PaymentComponent;
